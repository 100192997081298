<template>
  <div class="page-wrapper Carrer">
    <!-- Slide Image disp -->
    <section>
      <img src="../assets/img/career-img.jpeg" alt="This is an slider image" />
    </section>

    <!-- Slide Image disp -->

    <section class="contact spad">
      <div class="container" id="concon">
        <div class="row">
          <div class="col-lg-6 col-md-6">
            <div class="contactcontent">
              <div class="contactform">
                <form action="#" @submit.prevent="submitForm">
                  <div style="max-width: 400px"></div>
                  <div
                    style="
                      display: flex;
                      padding-bottom: 18px;
                      max-width: 450px;
                    "
                  >
                    <div style="margin-left: 0; margin-right: 1%; width: 49%">
                      <b>First name</b><span style="color: red"> *</span><br />
                      <input
                        type="text"
                        id="data_1"
                        name="data_1"
                        style="max-width: 100%"
                        class="form-control"
                        v-model.trim="$v.message.fname.$model"
                        :class="{
                          'is-invalid':
                            !$v.message.fname.required &&
                            $v.message.fname.$dirty
                        }"
                      />
                      <div
                        class="text-danger"
                        v-if="
                          !$v.message.fname.required && $v.message.fname.$dirty
                        "
                      >
                        First Name is required
                      </div>
                    </div>
                    <div style="margin-left: 1%; margin-right: 0; width: 49%">
                      <b>Last name</b><span style="color: red"> *</span><br />
                      <input
                        type="text"
                        id="data_2"
                        name="data_2"
                        style="max-width: 100%"
                        class="form-control"
                        v-model.trim="$v.message.lname.$model"
                        :class="{
                          'is-invalid':
                            !$v.message.lname.required &&
                            $v.message.lname.$dirty
                        }"
                      />
                      <div
                        class="text-danger"
                        v-if="
                          !$v.message.lname.required && $v.message.lname.$dirty
                        "
                      >
                        Last Name is required
                      </div>
                    </div>
                  </div>
                  <div style="padding-bottom: 18px">
                    <b>Email</b><span style="color: red"> *</span><br />
                    <input
                      type="text"
                      id="data_4"
                      name="data_4"
                      style="max-width: 450px"
                      class="form-control"
                      v-model.trim="$v.message.email.$model"
                      :class="{
                        'is-invalid':
                          !$v.message.email.required && $v.message.email.$dirty
                      }"
                    />
                    <div
                      class="text-danger"
                      v-if="
                        !$v.message.email.required && $v.message.email.$dirty
                      "
                    >
                      Email is required
                    </div>
                  </div>
                  <div style="padding-bottom: 18px">
                    <b>Phone</b><span style="color: red"> *</span><br />
                    <input
                      type="tel"
                      id="data_5"
                      name="data_5"
                      style="max-width: 450px"
                      class="form-control"
                      maxLength="10"
                      v-model.trim="$v.message.contact.$model"
                      :class="{
                        'is-invalid':
                          !$v.message.contact.required &&
                          $v.message.contact.$dirty
                      }"
                    />
                    <div
                      class="text-danger"
                      v-if="
                        !$v.message.contact.required &&
                          $v.message.contact.$dirty
                      "
                    >
                      Phone is required
                    </div>
                  </div>

                  <div style="padding-bottom: 18px">
                    <b>Organization / Institutional Name</b><br />
                    <input
                      type="text"
                      id="data_4"
                      name="data_4"
                      v-model="message.organization"
                      style="max-width: 450px"
                    />
                  </div>
                  <div
                    style="
                      display: flex;
                      padding-bottom: 18px;
                      max-width: 450px;
                    "
                  >
                    <div style="margin-left: 0; margin-right: 1%; width: 49%">
                      <b>When Can You Start</b><span style="color: red"> *</span
                      ><br />
                      <input
                        type="text"
                        id="data_1"
                        name="data_1"
                        style="max-width: 100%"
                        class="form-control"
                        v-model.trim="$v.message.start.$model"
                        :class="{
                          'is-invalid':
                            !$v.message.start.required &&
                            $v.message.start.$dirty
                        }"
                      />
                      <div
                        class="text-danger"
                        v-if="
                          !$v.message.start.required && $v.message.start.$dirty
                        "
                      >
                        When Can You Start is required
                      </div>
                    </div>
                    <div style="margin-left: 1%; margin-right: 0; width: 49%">
                      <b>Salary Expectations</b
                      ><span style="color: red"> *</span><br />
                      <input
                        type="number"
                        id="data_2"
                        name="data_2"
                        style="max-width: 100%"
                        class="form-control"
                        v-model.trim="$v.message.salary.$model"
                        :class="{
                          'is-invalid':
                            !$v.message.salary.required &&
                            $v.message.salary.$dirty
                        }"
                      />
                      <div
                        class="text-danger"
                        v-if="
                          !$v.message.salary.required &&
                            $v.message.salary.$dirty
                        "
                      >
                        Salary Expectations is required
                      </div>
                    </div>
                  </div>
                  <div style="padding-bottom: 18px">
                    <b>Comments</b><br />
                    <textarea
                      id="data_6"
                      false
                      v-model="message.comments"
                      name="data_6"
                      style="max-width: 450px"
                      rows="6"
                    >
                    </textarea>
                  </div>
                  <div class="filein">
                    <input
                      type="file"
                      accept="application/pdf, application/msword"
                      name="imageUpload"
                      @change="uploadFile"
                      id="imageUpload"
                      class="hiden"
                    />
                    <label for="imageUpload" class="btn btn-large"
                      ><b>Upload Resume</b></label
                    >
                    <span
                      v-if="filename != null"
                      style="
                        font-size: 15px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      "
                    >
                      {{ filename }}
                      <span class="delete" @click="deleteFile"> x </span>
                    </span>
                  </div>
                  <v-btn
                    class="ma-2 submitbtn"
                    type="submit"
                    :loading="loading"
                    :disabled="loading"
                  >
                    Send
                  </v-btn>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Contact Section End -->
  </div>
</template>

<script>
import { required, email, maxLength } from "vuelidate/lib/validators";
//eslint-disable-next-line
import { fb } from "../firebase.js";
//eslint-disable-next-line
import axios from "axios";
export default {
  name: "Carrer",
  components: {},
  data() {
    return {
      sending: false,
      loading: false,
      filename: null,
      show: false,
      message: {
        fname: null,
        lname: null,
        email: null,
        contact: null,
        organization: null,
        start: null,
        salary: null,
        comments: null,
        file: null,
        date: null
      }
    };
  },
  validations: {
    message: {
      fname: {
        required
      },
      lname: {
        required
      },
      email: {
        required,
        email
      },
      contact: {
        required,
        maxLength: maxLength(10)
      },
      start: {
        required
      },
      salary: {
        required
      }
    }
  },
  methods: {
    uploadFile(e) {
      if (e.target.files[0]) {
        let file = e.target.files[0];
        const size = Math.round(file.size / 1024);
        if (size > 2000) {
          alert("File size is Greater than 2mb");
        } else {
          this.filename = file.name;
          var storageRef = fb.storage().ref("carrerFiles/" + file.name);

          let uploadTask = storageRef.put(file);

          uploadTask.on(
            "state_changed",
            //eslint-disable-next-line
            (snapshot) => {
              // console.log(snapshot);
            },
            error => {
              console.log(error);
            },
            () => {
              uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
                this.message.file = downloadURL;
              });
            }
          );
        }
      }
    },
    deleteFile() {
      let image = fb.storage().refFromURL(this.message.file);
      image
        .delete()
        .then(function() {
          // console.log("file deleted");
        })
        .catch(function(err) {
          console.log(err);
          console.log("err ocurred");
        });
      this.filename = null;
    },
    reset() {
      this.message.fname = "";
      this.message.lname = "";
      this.message.email = "";
      this.message.contact = "";
      this.message.organization = "";
      this.message.start = "";
      this.message.salary = "";
      this.message.comments = "";
    },
    submitForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        var date = new Date();
        date.toISOString();
        axios
          .post(
            "https://asia-south1-mano-tools.cloudfunctions.net/mail/carrer",
            {
              fname: this.message.fname,
              lname: this.message.lname,
              email: this.message.email,
              salary: this.message.salary,
              organization: this.message.organization,
              startDate: this.message.start,
              contact: this.message.contact,
              comments: this.message.comments,
              file: this.message.file,
              date: date
            }
          )
          .then(res => {
            if (res.data == "mail sent") {
              this.reset();
              this.$v.$reset();
              this.loading = false;
              this.filename = null;
              this.message.file = null;
            } else {
              alert("Failed");
            }
          });
      } else {
        console.log("Submitted InCrctly");
      }
    }
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Carrer",
    // all titles will be injected into this template
    titleTemplate: "ManoTools - %s"
  }
};
</script>

<style lang="css" scoped>
#concon {
  margin-left: 30%;
}
.delete {
  background: red;
  color: white;
  border-radius: 25%;
  padding: 2px 6px;
  cursor: pointer;
  margin-left: 25px;
}
.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #6666f1 !important;
}
.v-progress-circular {
  color: white !important;
}
.submitbtn {
  font-size: 13px !important;
  margin-top: 30px !important;
  color: #ffffff !important;
  background: #010181 !important;
  font-weight: 600 !important;
  border: none !important;
  text-transform: uppercase !important;
  display: inline-block !important;
  padding: 12px 30px !important;
  border-radius: 50px !important;
}
img {
  width: 100%;
}
.contact {
  padding-top: 80px;
  padding-bottom: 80px;
}

.contactcontent {
  margin-bottom: 45px;
}

.site-btn {
  font-size: 13px;
  margin-top: 40px;
  color: #ffffff;
  background: #010181;
  font-weight: 600;
  border: none;
  text-transform: uppercase;
  display: inline-block;
  margin-left: 25%;
  padding: 25px 55px;
  border-radius: 50px;
}

.hiden {
  display: none;
}

.btn {
  display: inline-block;
  padding: 4px 12px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid #ddd;
  box-shadow: 2px 2px 10px #eee;
  border-radius: 4px;
}

.btn-large {
  padding: 11px 19px;
  font-size: 17.5px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

#imagePreview {
  margin: 15px 0 0 0;
  border: 2px solid #ddd;
}

.contactform form input {
  height: 50px;
  width: 100%;
  padding-left: 20px;
  font-size: 14px;
  color: #444444;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
}

label {
  margin-top: 20px;
}
.contactform form textarea {
  height: 130px;
  width: 100%;
  padding-left: 20px;
  padding-top: 12px;
  font-size: 14px;
  color: #444444;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  margin-bottom: 14px;
  resize: none;
}

@media screen and (max-width: 770px) {
  .site-btn {
    margin-left: 20%;
  }
}

@media screen and (max-width: 830px) {
  #concon {
    margin-left: 10%;
  }
}

@media screen and (max-width: 530px) {
  #concon {
    margin-left: 0%;
  }

  .site-btn {
    margin-left: 0%;
  }
}
</style>
